import React from 'react';
import {Link} from "react-router-dom";
import Themes from '../themes/Themes';
import "./TopMenu.scss";

function TopMenu() {
    const contactForm = "bd954bef-92f9-4c94-ab26-d02309c45c9e";

    return (
        <div className="top-menu">
            <Themes/>
            {/* <a className={'top-menu-icon'} target="_blank" href="https://discord.com"><div className="discord"></div></a> */}
            <Link to={{
                pathname: `/`
            }}>
                Scripts
            </Link>
            <a href="https://www.youtube.com/c/Zxivt" target="_blank">
                Subscribe
            </a>
            <Link to={{
                pathname: `/info`
            }}>
                Info
            </Link>
            {contactForm ? <Link to={{pathname: `/contact`}}>Contact</Link> : null}
        </div>
    );
}

export default TopMenu;
